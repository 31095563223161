import React, { Component } from 'react';
import {EducationImg, EducationContent, EducationWrapper} from './Education.styles';
import { SeparatorVertical, SectionShadowWrapper } from '../../styles/common';
import { RightColumn, ExperiencesContainer } from '../Work/Work.styles';
import Experience from './experience';
import {SectionTitle} from '../common';

const Education = () => {

    const educationInfo = {
        experiences: [
            {
                title: 'New York University, Leonard N.Stern School of Business' ,
                subtitle: 'Master of Business Administration, Tech MBA Program' ,
                date: 'Jan 2021 - Current',
                description: 'Specializations in Tech Product Management and Business Analytics'
            },
            {
                title: 'Illinois Institute of Technology' ,
                subtitle: 'Master in Information and Technology Management' ,
                date: 'Aug 2014 - May 2015',
                description: 'Specializations in Entrepreneurship and Application Development'
            },
            {
                title: 'Universidad Politecnica de Madrid' ,
                subtitle: 'Bachelor and Master of Science in Telecommunication Engineering' ,
                date: 'Sept 2009 - July 2015',
                description: 'Specialization in Communications'
            }
        ]
    };

    const experiencesRight = educationInfo.experiences.map(experience => {
      return <Experience key={experience.date} flexAlignment='flex-start' title={experience.title} subtitle={experience.subtitle} date={experience.date} description={experience.description} />
    });

  	return (
    <SectionShadowWrapper>
      <EducationContent>
       <EducationWrapper>
           <SectionTitle title="Education" subtitle="Learning" />
           <ExperiencesContainer>
              <SeparatorVertical/>
              <RightColumn>
                  {experiencesRight}
              </RightColumn>
            </ExperiencesContainer>
        </EducationWrapper>
         <EducationImg />
         </EducationContent>
      </SectionShadowWrapper>
    );

}

export default Education;
