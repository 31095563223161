import React from 'react';
import {TitleWrapper, Subtitle, NameWrapper } from './Title.styles';
import { Button } from '../../styles/common';
import pdf from '../../files/resume.pdf';
// import  from '../files/myfile.pdf'

import styled from 'styled-components';


const NameSpan = styled.span`
  color: var(--white);
  font-size: var(--fontL);
  padding-left: 10px;

  @media screen and (min-width: 768px) {
     font-size: var(--fontXL);
     padding-left: 15px;
  }
  `;


const Title = () => {
  	return (
      <TitleWrapper>
        <NameWrapper>
        HELLO,<NameSpan> I am</NameSpan>
       </NameWrapper>
          <Subtitle>BEATRIZ FUSTER GONZALEZ</Subtitle>
        {/* <a href={pdf} target="_blank"> */}
        <a href="#aboutme">
        <Button maintitle>About Me</Button>
        </a>
      </TitleWrapper>
    );
}

export default Title;
