
import Title from './Title';
import AboutMe from './AboutMe';
import Work from './Work';
import Education from './Education';
import Skills from './Skills';
import {OverallContainer, ContentContainer} from '../styles/common'

const Home = () => {
  return (
    <OverallContainer>
        <Title />
        <ContentContainer>
          <AboutMe />
          <Work />
          <Education />
          <Skills />
        </ContentContainer>
    </OverallContainer>
  );
}

export default Home;
