import React, { Component, useEffect} from 'react';
import { AboutMeContent, MeCont, ProfilePicture, ProfilePictureContainer} from './AboutMe.styles';
import { Separator } from '../../styles/common';
import ContactInfo from './contactInfo';
import Summary from './summary';
import { SectionShadowWrapper } from '../../styles/common';
import {SectionTitle} from '../common';
import Aos from 'aos';
import 'aos/dist/aos.css'; 


const AboutMe = () => {

  useEffect(()=> {
    Aos.init({
      duration: 2000
    });
  }, []); 

  	return (
        <SectionShadowWrapper id="aboutme">
              <AboutMeContent>
                <ProfilePictureContainer>
                  <ProfilePicture/>
                </ProfilePictureContainer>
                <MeCont>
                    <SectionTitle title="about me" subtitle="who am I"/>
                    <Summary/>
                    <Separator />
                    <ContactInfo/>
                </MeCont>
              </AboutMeContent>
        </SectionShadowWrapper>
    );
}

export default AboutMe;
