import './App.css';

//Routing 
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import { GlobalStyle } from './styles/common';

import Home from './components/Home';
import Art from './components/Art';
import Social from './components/Social';

function App() {
  return (
  //we wrap the complete app withg the router
      <Router>
      <Routes>
        <Route path='/' element={<Home/>} />
        <Route path='/art' element={<Art/>} />
        <Route path='/social' element={<Social/>} />
      </Routes>
      <GlobalStyle />
    </Router>
  );
}

export default App;
