
import Title from './Title';

const Art = () => {
  return (
    <div id='art'>
        <Title />
    </div>
  );
}

export default Art;
