import React, { Component, useEffect } from 'react';
import { Subtitle, MainTitle, SectionTitleWrapper } from '../styles/common';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

export const SectionTitle = ({title, subtitle}) => {

  useEffect(()=> {
    Aos.init({
      duration: 2000
    });
  }, []); 

  	return (
    <SectionTitleWrapper>
      <MainTitle data-aos="fade-right">{title}</MainTitle>
      <Subtitle data-aos="fade-left">{subtitle}</Subtitle>
    </SectionTitleWrapper>
    );
}
