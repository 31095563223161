import styled,  { css, createGlobalStyle }  from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --maxWidth:1280px;
        --white: #fff;
        --lighterGray: #fbfbfb;
        --lightGray: #eee;
        --gray: #858585;
        --medGray: #666;
        --darkGray: #030303;
        --brandPrimary: #ffd351;
        --brandPrimaryDark: #FFC20A;
        --fontXXL: 3rem;
        --fontXL: 2.4rem;
        --fontL: 1.5rem;
        --fontM: 1.2rem;
        --fontS: 1rem; 
        --ipad: 768px;
        --ipadPro: 1024px;
        --iphonePlus: 414px;
        --iphone: 375px;
    }

    * {
        box-sizing: border-box;
        font-family: 'Montserrat', sans-serif;
    }

    body {
        margin: 0;
        padding: 0 ;

        h1 {
            font-size : 2rem;
            font-weight: 600;
            color: var(--white)
        }

        h3 {
            font-size : 1.1rem;
            font-weight: 600;
        }

        p {
            font-size : 1rem;
            color: var(--white)
        }

    }
`

export const Button = styled.button`
  border-radius: 2px;
  padding: 0.25em 1em;
  margin: 0 1em;
  background: transparent;
  color: white;
  border: 2px solid white;
  font-family: 'Poppins', sans-serif;

  &:hover {
      background-color: white;
      color: #080808;
  }

    ${props => props.maintitle && css`
    font-size: 20px;
    margin-top: 20px;
    `}
`;


export const Separator = styled.div`
  width: 100%;
  border-bottom: solid 2px var(--lightGray);

  ${props => props.maintitle && css`
  border-bottom: solid 4px #FFF;
  width: 800px;
  `}
`;

export const SeparatorVertical = styled.div`
  height: auto;
  border-right: solid 2px var(--brandPrimary);
  margin-top: 5px;

  ${props => props.dark && css`
      border-right: solid 2px #666;
  `}
`;



export const MainTitleOld = styled.h1`
  margin-bottom: 15px;
  color: var(--brandPrimary);//#5bc0de;
  ${'' /* color: #EA4858; */}
  font-size: 40px;
  font-family: 'Slabo 27px', serif;
  font-weight: bold;
`;

// export const MainTitle2 = styled.h2`
//   margin-bottom: 15px;
//   color: var(--brandPrimary);
//   font-family: 'Slabo 27px', serif;
//   font-weight: bold;
// `;

export const AlignedCenterWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`;

export const SectionShadowWrapper = styled.div`
    transition: .3s all ease-in-out;
    box-shadow: 0px 15px 45px -9px rgba(0,0,0,.2);
    width: 100%;

    @media screen and (min-width: 700px) {
        margin: 30px 0 ;
    }
`;

export const SectionTitleWrapper = styled.div`
    display: inline-block;
    position: relative;
    width: fit-content;
    margin-bottom: 30px;
`;

export const MainTitle = styled.h1`
  font-size: 40px;
  font-size: var(--fontL);
  margin: 0px;
  text-transform: uppercase;
  position: relative;
  width: auto;
  font-weight: 500;
  padding-bottom: 15px;
  padding-top: 15px;
  font-family: 'Montserrat', sans-serif;
  color: #202020;

  @media screen and (min-width: 768px) {
        font-size: var(--fontXL);
  }

  &:after {
    content: "";
    position: absolute;
    /* background-color: var(--brandPrimary);//;#202020; */
    background-color: var(--brandPrimary);
    width: 100%;
    height: 2px;
    top: 100%;
    left: 0;
  }
`;

export const Subtitle = styled.p`
  text-align: right;
  width: auto;
  text-transform: uppercase;
  background-color: #ffffff;
  margin: 0px;
  padding-left: 10px;
  position: absolute;
  right: 0;
  bottom: -10px;
  color: #666;
  font-weight: 500;
`;

export const IconWrapper = styled.span`
  /* border-radius: 100%; */
  /* border: 1px solid #666; */
  background-color: #fff;
  /* color: #666; */
  color: var(--medGray);
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  /* margin-right: 8px; */
  /* box-shadow: 3px 3px 5px #808080; */
`;

export const FlexInlineWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const FlexColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 16px;
`;


export const ColumnWrapper = styled.div`
  padding: 30px;
  background-color: #FFF;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ColumnWrapperNoPad = styled.div`
  background-color: var(--white);
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const ContentContainer = styled.div`
  max-width: 1024px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const OverallContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
`;




