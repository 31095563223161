import React, { Component, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { faPhoneAlt, faEnvelope, faHome } from '@fortawesome/free-solid-svg-icons'
import { faLinkedinIn} from '@fortawesome/free-brands-svg-icons'


import { ContactInfoCont, InfoCont } from './AboutMe.styles';
import { IconWrapper, FlexInlineWrapper, FlexColumnWrapper } from '../../styles/common';
import Aos from 'aos';
import 'aos/dist/aos.css'; 

const ContactInfo = () => {

    useEffect(()=> {
      Aos.init({
        duration: 2000
      });
    }, []); 

    const contactIndo = {
      email: 'beatrizfusterg@gmail.com',
      phone: '+1 646 704 5943',
      address: '300 Mercer St, New York',
      linkedin: 'www.linkedin.com/in/beatrizfusterg'
    };

  	return (
      <ContactInfoCont>
            <InfoCont><IconWrapper><FontAwesomeIcon icon={faEnvelope} /></IconWrapper>{contactIndo.email}</InfoCont>
            <InfoCont><IconWrapper><FontAwesomeIcon icon={faHome} /> </IconWrapper>{contactIndo.address}</InfoCont>
            <InfoCont><IconWrapper><FontAwesomeIcon icon={faPhoneAlt} /></IconWrapper> {contactIndo.phone}</InfoCont>
            <InfoCont><IconWrapper><FontAwesomeIcon icon={faLinkedinIn}/> </IconWrapper>{contactIndo.linkedin}</InfoCont>
      </ContactInfoCont>
    );

}

export default ContactInfo;
