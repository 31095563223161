import styled,  { css }  from 'styled-components';
import beaNYU from '../../img/beaNYU.jpg';


export const AboutMeContent = styled.div`
  background-color: var(--lighterGray);
  position: relative;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100%;
  font-size: 14px;

  @media screen and (min-width: 768px) {
        font-size: var(--fontS);
  }
`;

export const SummaryCont = styled.div`
  padding: 15px 0;
`;

export const ContactInfoCont = styled.div`
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(360px, 10fr));
  /* grid-gap: 1rem;  */
`;

export const InfoCont = styled.div`
  display: flex;
  align-items: center;
  padding: 0 8px;
`;

export const MeCont = styled.div`
  background-color: var(--white);
  padding: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @media screen and (min-width: 768px) {
        width: 70%;
  }

  @media screen and (min-width: 1024px) {
        width: 80%;
  }

`;

export const ProfilePicture = styled.div`
  background: url(${beaNYU});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  border-radius: 50%;
  height: 180px;
  width: 180px;
  max-width: 100%;
`;

export const ProfilePictureContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
  @media screen and (min-width: 768px) {
        width: 30%;
  }

  @media screen and (min-width: 1024px) {
        width: 20%;
  }
`;

