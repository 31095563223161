import React, { Component } from 'react';
import { SummaryCont } from './AboutMe.styles';

const Summary = () => {
  	return (
      <SummaryCont>
        I am a Telecommunications engineer and front-end developer with more than 5 years of experience in building digital products working cross-functionally with Product, UX to ship solutions that meet the business and the customer needs.

I am passionate about SCRUM Agile methodology and love learning new technologies and tools.
Besides coding, creativity is one of my strengths, I bring it to work by including videos and sketches to share ideas, knowledge and make daily meetings more engaging.
Ready to broaden my knowledge in Product Management and bring my development experience to the table.
      </SummaryCont>
    );
}

export default Summary;
