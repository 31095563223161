import React, { Component } from 'react';
import { ExperienceWrapper, ExperienceTitle, ExperienceSubtitle, ExperienceDate, ExperienceDateLeft, ExperienceText } from './Education.styles';


const Date = ({ text}) => {
    return  <ExperienceDate>{text}</ExperienceDate>;
}

const Experience = ({subtitle, title, date, description,flexAlignment}) => {
  	return (
      <ExperienceWrapper flexAlignment={flexAlignment}>
        <Date text={date}/>
        <ExperienceTitle> {title} </ExperienceTitle>
        <ExperienceSubtitle> {subtitle} </ExperienceSubtitle>
        <ExperienceText>
          {description}
        </ExperienceText>
      </ExperienceWrapper>
    );
}

export default Experience;
