import styled,  { css }  from 'styled-components';

import tech from '../../img/tech.jpg';
import sketching from '../../img/skething.jpg';
import travel from '../../img/travel.jpg';
import abstract from '../../img/abstract.jpg';
import social from '../../img/kids.JPG';


export const SkillsContent = styled.div`
  background-color: #FFF;
  position: relative;
  color: #666;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  /* flex-direction: row;
  flex-wrap: wrap; */
  width: 100%;
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); 
  grid-gap: 2rem;

  @media screen and (min-width: 768px) {
        grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
        padding: 0 20px;
  }
`;

export const SkillWrapper = styled.div`
    margin: 30px;
    transition: .3s all ease-in-out;
    box-shadow: 0px 15px 45px -9px rgba(0,0,0,.2);
    flex: 3;
    min-width: 250px;

    :hover {
        background-color: var(--medGray);
        opacity: 0.8;
        color: white;
        cursor: pointer;
    }
`;


export const SkillText = styled.div`
    padding: 16px;
    height: 200px;
`;

export const SkillTitle = styled.div`
    padding-bottom: 8px;
    font-weight: bold;
    font-size: 16px;
`;

export const TechPicture = styled.div`
  background: url(${tech});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 200px;
  width: 100%;
  flex: 3;
`;


export const SketchingPicture = styled.div`
  background: url(${sketching});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 200px;
  flex: 3;
`;


export const TravelPicture = styled.div`
  background: url(${travel});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 200px;
  flex: 3;
`;



export const BeaArtPicture = styled.div`
  background: url(${abstract});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 200px;
  flex: 3;
`;


export const SocialPicture = styled.div`
  background: url(${social});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 200px;
  flex: 3;
`;