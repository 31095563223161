import React, { Component } from 'react';
import { ExperienceWrapper, ExperienceTitle, ExperienceDate, ExperienceDateLeft, ExperienceText } from './Work.styles';


const Date = ({left, text}) => {
    return left ? <ExperienceDateLeft>{text}</ExperienceDateLeft> : <ExperienceDate>{text}</ExperienceDate>;
}

const Experience = ({title, date, description,flexAlignment, visible, left}) => {

  	return (
      <ExperienceWrapper flexAlignment={flexAlignment} visible={visible}>
        <Date left={left} text={date}/>
        <ExperienceTitle left={left}> {title} </ExperienceTitle>
        <ExperienceText left={left}>
          {description}
        </ExperienceText>
      </ExperienceWrapper>
    );
}

export default Experience;
