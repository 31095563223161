import styled,  { css, keyframes }  from 'styled-components';
import wheel from '../../img/wheel.jpg';

const breatheAnimation = keyframes`
 0% { height: 100px; width: 100px; }
 30% { height: 400px; width: 400px; opacity: 1 }
 40% { height: 405px; width: 405px; opacity: 0.3; }
 100% { height: 100px; width: 100px; opacity: 0.6; }
`

const slideInAnimation = keyframes`
  0% {
    opacity: 0.5;
    transform: translateX(100px);
  }
  50% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
`

export const TitleWrapper = styled.div`
  padding: 30px;
  background: no-repeat center right fixed url(${wheel});
  background-color: rgba(20,44,47,0.8);
  position: relative;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  font-family: 'Montserrat', sans-serif;
  width: 100%;
`;

export const Subtitle = styled.div`
  font-size: var(--fontL);
  color: #FFF;
  font-family: 'Montserrat', sans-serif;
  animation-name: ${slideInAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  text-align: center;

  @media screen and (min-width: 768px) {
     font-size: var(--fontXL);
  }
`;


export const NameWrapper = styled.h1`
  margin-bottom: 15px;
  color: var(--brandPrimary);
  font-size: var(--fontXL);
  font-family: 'Montserrat', sans-serif;
  animation-name: ${slideInAnimation};
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-fill-mode: forwards;

  @media screen and (min-width: 768px) {
     font-size: var(--fontXXL);
  }
`;
