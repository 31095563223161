import styled,  { css }  from 'styled-components';
import chicago from '../../img/chicago.jpg';

export const WorkWrapper = styled.div`
  padding: 30px 5px;
  margin-bottom: 25px;
  color: #FFF;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: no-repeat center right fixed url(${chicago});
  background-color: rgba(20,44,47,0.8);
  position: relative;
  margin-bottom: 25px;
  background-size: cover;
  background-position: top center;
  background-repeat: no-repeat;
  overflow: hidden;
  width: 100%;
  @media screen and (min-width: 768px) {
    padding: 30px;
  }
`;


export const LeftColumn = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
`;

export const RightColumn = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
`;

export const ExperiencesContainer = styled.div`
    display: flex;
`;
export const ExperienceSpace = styled.div`
    height: 150px;
`;


export const ExperienceWrapper = styled.div`
  padding: 0 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.flexAlignment};
  visibility: ${props => props.visible};
`;


export const ExperienceTitle = styled.span`
 font-size: var(--fontS);
 ${props => props.left && css`
    text-align: right;
  `}

  @media screen and (min-width: 768px) {
        font-size: var(--fontM);
  }

`;

export const ExperienceText = styled.p`
  color: #dde0e4;
  font-size: var(--fontS);
  ${props => props.left && css`
    text-align: right;
  `}
`;


export const ExperienceDate = styled.h5`
  color: var(--brandPrimary);
  position: relative;
  font-size: var(--fontS);
  @media screen and (min-width: 768px) {
        font-size: var(--fontM);
  }
  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--brandPrimary);
    position: absolute;
    position: absolute;
    top: 3.5px;
    left: -28.75px;

  }
`;

export const ExperienceDateLeft = styled.h5`
  color: var(--brandPrimary);
  position: relative;
  font-size: var(--fontS);
  @media screen and (min-width: 768px) {
        font-size: var(--fontM);
  }
  &:after {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: var(--brandPrimary);
    position: absolute;
    position: absolute;
    top: 3.5px;
    right: -28.75px;
  }
`;

