import React, { Component, useEffect } from 'react';
import { Separator, SeparatorVertical, AlignedCenterWrapper } from '../../styles/common';
import { WorkWrapper, LeftColumn, RightColumn, ExperiencesContainer } from './Work.styles';
import Experience from './experience';
import {SectionTitle} from '../common';
import Aos from 'aos';
import 'aos/dist/aos.css'; 



const Work = () => {

    const workInfo = {
        experiences: [
            {
                title: 'Tech Solutions MBA Consultant | Ripple Health Group' ,
                date: 'May 2021 – Aug 2021',
                description: 'Development of new product feature for LikePaper mobile application, consulting with company founder and CEO to specify feature prototypes and go-to-market strategy',
                visible: 'visible'
            },
            {
                title: 'Frontend Developer | Lastminute Group' ,
                date: 'Jan 2017 - Dec 2020',
                description: 'Built with latest Front-end technologies the Home, Results and Details pages of Hotel and Flights lastminute group sites.',
                visible: 'hidden'
            },
            {
                title: 'Lead Application Developer | Assurant Solutions' ,
                date: 'Sept 2015 - Dec 2016',
                description:'Designed and developed web and mobile applications that supported the company and the clients',
                visible: 'visible'
            },
            {
                title: 'Application Developer | MIP Corporation' ,
                date: 'June 2015 - Aug 2015',
                description:'In charge of designing and developing a prototype of the companys enterprise mobile application.',
                visible: 'hidden'
            },
            {
                title: 'Member of the organizing committee | Satelec 2014 Employment and Technology forum' ,
                date: 'Sept 2013 - May 2014',
                description:'Contacted business and corporations to get them enrolled into the forum.',
                visible: 'visible'
            }
        ],
        experiencesRight: [
            {
                title: 'Tech Solutions MBA Consultant | Ripple Health Group' ,
                date: 'May 2021 – Aug 2021',
                description: 'Development of new product feature for LikePaper mobile application, consulting with company founder and CEO to specify feature prototypes and go-to-market strategy',
                visible: 'hidden'
            },
            {
                title: 'Frontend Developer | Lastminute Group' ,
                date: 'Jan 2017 - Currently',
                description: 'Built with latest Front-end technologies the Home, Results and Details pages of Hotel and Flights lastminute group sites.',
                visible: 'visible'
            },
            {
                title: 'Lead Application Developer | Assurant Solutions' ,
                date: 'Sept 2015 - Dec 2016',
                description:'Designed and developed web and mobile applications that supported the company and the clients',
                visible: 'hidden'
            },
            {
                title: 'Application Developer | MIP Corporation' ,
                date: 'June 2015 - Aug 2015',
                description:'In charge of designing and developing a prototype of the companys enterprise mobile application.',
                visible: 'visible'
            },
            {
                title: 'Member of the organizing committee | Satelec 2014 Employment and Technology forum' ,
                date: 'Sept 2013 - May 2014',
                description:' In charge of designing and developing a prototype of the companys enterprise mobile application.',
                visible: 'hidden'
            }
        ]
    };

    const experiences = workInfo.experiences.map(experience => {
      return <Experience  data-aos="fade-right" key={experience.title} flexAlignment='flex-end' left title={experience.title} date={experience.date} description={experience.description} visible={experience.visible} />
    });
    const experiencesRight = workInfo.experiencesRight.map(experience2 => {
      return <Experience data-aos="fade-right" key={experience2.date} flexAlignment='flex-start' title={experience2.title} date={experience2.date} description={experience2.description} visible={experience2.visible} />
    });

    useEffect(()=> {
        Aos.init({
          duration: 2000
        });
      }, []); 


  	return (
        <AlignedCenterWrapper>
            <SectionTitle title="Experience" subtitle="work" />
            <WorkWrapper>
                <ExperiencesContainer>
                  <LeftColumn data-aos="fade-right">
                    {experiences}
                  </LeftColumn>
                  <SeparatorVertical/>
                  <RightColumn data-aos="fade-left">
                      {experiencesRight}
                  </RightColumn>
                </ExperiencesContainer>
            </WorkWrapper>
        </AlignedCenterWrapper>
    );

}

export default Work;
