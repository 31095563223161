import styled,  { css }  from 'styled-components';
import education from '../../img/books.jpg';

export const EducationImg = styled.div`
  background: url(${education});
  position: relative;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  overflow: hidden;
  color: white;
  height: 350px;
  display: none;
  @media screen and (min-width: 1024px) {
        display: flex;
        height: 100%;
        flex: 3;
        align-items: stretch;
  }
`;


export const EducationContent = styled.div`
  background-color: #FFF;
  position: relative;
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media screen and (min-width: 1024px) {
    height: 700px;
  }
`;


export const EducationWrapper = styled.div`
 padding: 30px;
 display: flex;
 flex-direction: column;
 margin-left: 30px;
 height: 100%;
 flex: 5;
 position: relative;
`;

export const ExperienceWrapper = styled.div`
  padding: 0 20px;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: ${props => props.flexAlignment};
  visibility: ${props => props.visible};
`;


export const ExperienceTitle = styled.h3`
  color: #666;
  font-weight: bold;
  padding: 4px;
  margin: 0;
  font-size: var(--fontS);
  @media screen and (min-width: 768px) {
    font-size: var(--fontM);
  }
`;

export const ExperienceSubtitle = styled.h5`
  color: var(--gray);
  padding: 4px;
  margin: 0;
  font-size: var(--fontS);
  @media screen and (min-width: 768px) {
      font-size: var(--fontM);
  }
`;

export const ExperienceText = styled.p`
    color: #666;
    padding: 4px;
    ${props => props.left && css`
      text-align: right;
      margin: 0;
    `}
  font-size: var(--fontS);
`;


export const ExperienceDate = styled.h5`
  color: var(--medGray);
  position: relative;
  font-size: var(--fontS);
  @media screen and (min-width: 768px) {
        font-size: var(--fontM);
  }
  &:before {
    content: '';
    display: inline-block;
    width: 15px;
    height: 15px;
    transform: rotate(45deg);
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #666;
    position: absolute;
    position: absolute;
    top: 3.5px;
    left: -28.75px;
    background-color: var(--brandPrimary);
  }
`;

