import React from 'react';
import {SkillsContent, SkillWrapper, SkillText, SkillTitle, TechPicture, SketchingPicture, TravelPicture, BeaArtPicture, SocialPicture} from './Skills.styles';
import { AlignedCenterWrapper } from '../../styles/common';
import {SectionTitle} from '../common';

import { Link } from 'react-router-dom';

const Skills = () => {
  	return (
        <AlignedCenterWrapper>
            <SectionTitle title="What I love" subtitle="skills"/>
            <SkillsContent>
                <SkillWrapper>
                    <TechPicture/>
                    <SkillText>
                    <SkillTitle>Tech lover</SkillTitle>
                        I am passionate about technology. I have been a front-end developer for more than 5 years. <br/>
                        React + Styledc Components + Storybook Lover
                    </SkillText>
                </SkillWrapper>
                <SkillWrapper>
                    <BeaArtPicture/>
                    <SkillText>
                    <SkillTitle>Abstract art</SkillTitle>
                        I'm my free time I love painting. I use sand to give texture and acrylics for color.
                    </SkillText>
                </SkillWrapper>
                <SkillWrapper>
                    <SketchingPicture/>
                    <SkillText>
                    <SkillTitle>Visual Thinking</SkillTitle>
                        I apply my art not only in my free time but at work. I love using shetches to represent ideas, roadmaps, prototypes, etc. 
                    </SkillText>
                </SkillWrapper>
                <Link to='/social'>
                    <SkillWrapper>
                        <SocialPicture/>
                        <SkillText>
                            <SkillTitle>Social Work</SkillTitle>
                            <ul>
                                <li> Member of Living Meki, organization to improve and promote education in Meki, Ethiopia. </li>
                                <li> I volunteered in Spain in a the Pediatric Cancer Section of El Niño Jesús. </li>
                            </ul>
                        </SkillText>
                    </SkillWrapper>
                </Link>
                <SkillWrapper>
                    <TravelPicture/>
                    <SkillText>
                        <SkillTitle>Travel</SkillTitle>
                        I have travelled around 44 countries. I love learning about new cultures and traditions. I have lived in 3 countries (USA, England and Spain)
                    </SkillText>
                </SkillWrapper>
            </SkillsContent>
        </AlignedCenterWrapper>
    );
}

export default Skills;
