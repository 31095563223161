import { OverallContainer, ContentContainer, SectionShadowWrapper } from '../styles/common';
import {} from '../styles/common'
import styled from 'styled-components';

//Image
import girls from '../img/girls.JPG';

const SocialContent = styled.div`
  color: #666;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 100%;

  p, h1{
    color: #666;
  }
`;

const TextContent = styled.div`
  color: #666;
`;

const ImageContent = styled.div`
  width: 400px;

  img {
      width: 100%;
  }
`;
const Social = () => {
  return (
    <OverallContainer>
      <ContentContainer>
      <SectionShadowWrapper>
        <SocialContent>
            <ImageContent>
             <img src={girls} alt='girls-club' />
            </ImageContent>
            <TextContent>
            <h1>Living Meki</h1>
            <p>
            Living Meki's goal is simple, to help the community of Meki, in Ethiopia, have more opportunities.
            To this end, we finance education, health, infrastructure and women's empowerment programs, among others. Our main role is to obtain funds for the execution of projects co-identified with our local partner, the Secretariat, the largest social entity in Meki. Every summer we organize a volunteer service that serves to publicize the initiative and monitor the progress of our projects on the ground.
            </p>
            </TextContent>
        </SocialContent>
    </SectionShadowWrapper>
      </ContentContainer>
    </OverallContainer>
    
  );
}

export default Social;



